import "./style/main.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { createLights, modelLoader } from "../static/utils/";
import axios from "axios";
import gsap from "gsap";

let canvas, renderer, payload;

const scenes = [],
  sizeUnit = 250;

init();
animate();

const main = async (path, scene, progress) => {
  const fbxData = await modelLoader(path, progress, scene); // FBX model load
  const box = new THREE.Box3().setFromObject(fbxData);
  const size = box.getSize(new THREE.Vector3());
  const clampDot = size.clampLength(10, 30);
  const scaleUnit = size.dot(clampDot);

  fbxData.scale.set(1 / scaleUnit, 1 / scaleUnit, 1 / scaleUnit);
  fbxData.traverse((item) => {
    if (item.isMesh) {
      item.castShadow = true;
      item.receiveShadow = true;
    }
  });
  fbxData.userData.box = new THREE.Box3().setFromObject(fbxData);
  fbxData.userData.size = fbxData.userData.box.getSize(new THREE.Vector3());
  fbxData.userData.sizeUnit =
    (fbxData.userData.size.x +
      fbxData.userData.size.y +
      fbxData.userData.size.z) /
    3;

  scene.userData.size = fbxData.userData.sizeUnit;
  scene.userData.loadModel = true;
  scene.add(fbxData);

  // Lights

  createLights(fbxData.userData.sizeUnit).map((light) => scene.add(light));
};

const content = document.querySelector("#content");

const createViewports = (data, i, selector) => {
  const scene = new THREE.Scene();
  const textureLoader = new THREE.TextureLoader();
  const backgroundTexture = textureLoader.load("./assets/back.jpg");
  scene.background = backgroundTexture;
  // make a list item
  const element = document.createElement("div");
  element.classList.add("list-item");

  const sceneElement = document.createElement("div");
  sceneElement.classList.add("sceneElement", "background");
  scene.userData.fbx = data.fbx_url;
  sceneElement.style.backgroundImage = `url(${
    data.starting_image ? data.starting_image : "./assets/back.jpg"
  })`;
  sceneElement.dataset.index = i;

  const resizeBtn = document.createElement("button");
  sceneElement.appendChild(resizeBtn);
  resizeBtn.classList.add("resizeBtn");

  const info = document.createElement("div");
  let infoContent = ` 
                            <h2>VIEW THE STAND</h2>                    
                            <p> ● Size in sqm:  ${data.size} ● Label:  ${
    data.label
  } ● Amount of TVs:  ${data.tv_amount} ● Availability of logo:  ${
    data.logo_availability ? "Yes" : "No"
  } ● Availability of website integration: Available:  ${
    data.website_integration ? "Yes" : "No"
  } ● Amount of presentations (in PDF format):  ${data.presentation_amount}</p>
                        `;

  info.innerHTML = infoContent;
  info.classList.add("info");
  sceneElement.appendChild(info);

  const infoOnBoard = info.cloneNode(true);
  infoOnBoard.classList.remove("info");
  infoOnBoard.classList.add("resizeInfo", "hide");
  sceneElement.appendChild(infoOnBoard);

  resizeBtn.onclick = resizeBtn.ontouchstart = (e) => {
    e.preventDefault();
    document.querySelectorAll(".list-item").forEach((item) => {
      item.classList.toggle("hide");
    });

    document
      .querySelectorAll(".filteredSize")
      .forEach((item) => item.firstChild.classList.toggle("hide"));
    document
      .querySelectorAll(".filteredSize")
      .forEach((item) => item.lastChild.classList.toggle("hide"));

    infoOnBoard.classList.toggle("hide");
    if (info) info.remove();

    element.classList.toggle("show");
    element.classList.toggle("resize");
  };

  element.appendChild(sceneElement);

  const descriptionElement = document.createElement("span");
  descriptionElement.dataset.title = `Scene ${data.title}`;
  descriptionElement.dataset.size = data.size;

  descriptionElement.innerText = `Scene - ${data.title}`;
  element.appendChild(descriptionElement);

  const progress = document.createElement("div");
  progress.className = "progress";

  element.append(progress);

  // the element that represents the area we want to render the scene
  scene.userData.element = sceneElement;
  selector.insertBefore(element, selector.querySelector(".load"));

  const rect = element.getBoundingClientRect();
  // set the viewport
  const width = rect.right - rect.left;
  const height = rect.bottom - rect.top;

  const camera = new THREE.PerspectiveCamera(45, width / height, 0.01, 1000);
  scene.userData.camera = camera;
  scene.userData.camera.position.set(sizeUnit * 8, sizeUnit * 8, sizeUnit * 8);

  // Camera controls

  const controls = new OrbitControls(
    scene.userData.camera,
    scene.userData.element
  );

  scene.userData.controls = controls;

  scene.userData.controls.enabled = false;

  scenes.push(scene);

  // Mouse Events on scene Element

  const timeLine = (scene) => {
    return gsap.to(scene.userData.camera.position, {
      duration: 1.5,
      x: 0,
      y: scene.userData.size * 2,
      z: scene.userData.size * 2,
      ease: "power4.out",
      onUpdate: () => {
        scene.userData.camera.updateProjectionMatrix();
        scene.userData.controls.update();
        scene.userData.controls.autoRotate = true;
      },
    });
  };

  sceneElement.onclick = sceneElement.ontouchstart = async (e) => {
    if (!scene.userData.loadModel) {
      sceneElement.style.backgroundImage = "none";
      scene.userData.controls.enabled = true;
      if (info) info.remove();
      let path = scene.userData.fbx;
      await main(path, scene, progress);
      timeLine(scene);
    }
  };

  sceneElement.onpointerenter = (e) => {
    if (scene.userData.loadModel) {
      scene.userData.controls.autoRotate = true;
    }
  };

  sceneElement.onpointerleave = (e) => {
    scene.userData.controls.autoRotate = false;
  };

  sceneElement.onmousedown = (e) => {
    sceneElement.style.cursor = "grabbing";
  };

  sceneElement.onmouseup = (e) => {
    sceneElement.style.cursor = "grab";
  };

  if (i > 5) {
    element.style.display = "none";
  } else {
    element.parentElement.querySelector(".load button").dataset.index = i;
  }
};

function init() {
  canvas = document.querySelector("#canvas");

  const fetchData = async () => {
    const res = await axios.get(`https://expo-api.pandamr.com/api/designs/`);
    payload = res.data.data.slice(0);
    console.log(payload);
    const size_18 = payload.filter((item) => item.size === 18);
    const size_36 = payload.filter((item) => item.size === 36);
    const size_72 = payload.filter((item) => item.size === 72);

    size_18.forEach((data, i) =>
      createViewports(data, i, content.querySelector(".size_18"))
    );
    size_36.forEach((data, i) =>
      createViewports(data, i, content.querySelector(".size_36"))
    );
    size_72.forEach((data, i) =>
      createViewports(data, i, content.querySelector(".size_72"))
    );

    document.querySelector(".size_18 .load button").onclick = (e) => {
      let index = e.target.dataset.index;
      document.querySelectorAll(".size_18 .list-item").forEach((node, i) => {
        if (i > Number(index) && i <= Number(index) + 6 && i < size_18.length) {
          node.style.display = "";
        }
      });
      e.target.dataset.index = Number(index) + 6;
      if (Number(e.target.dataset.index) > size_18.length - 1) {
        e.target.style.display = "none";
      }
    };

    document.querySelector(".size_36 .load button").onclick = (e) => {
      let index = e.target.dataset.index;
      document.querySelectorAll(".size_36 .list-item").forEach((node, i) => {
        if (i > Number(index) && i <= Number(index) + 6 && i < size_36.length) {
          node.style.display = "";
        }
      });
      e.target.dataset.index = Number(index) + 6;
      if (Number(e.target.dataset.index) > size_36.length - 1) {
        e.target.style.display = "none";
      }
    };

    document.querySelector(".size_72 .load button").onclick = (e) => {
      let index = e.target.dataset.index;
      document.querySelectorAll(".size_72 .list-item").forEach((node, i) => {
        if (i > Number(index) && i <= Number(index) + 6 && i < size_72.length) {
          node.style.display = "";
        }
      });
      e.target.dataset.index = Number(index) + 6;
      if (Number(e.target.dataset.index) >= size_72.length - 1) {
        e.target.style.display = "none";
      }
    };
  };

  fetchData();

  // Renderer

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    preserveDrawingBuffer: true,
    alpha: true,
  });

  renderer.setClearColor(0xf9f9f9, 0);
  renderer.physicallyCorrectLights = true;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.8;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(window.devicePixelRatio);

  setTimeout(function () {
    document.querySelector(".loader").style.display = "none";
  }, 1000);
}

onWindowResize();

function onWindowResize() {
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;

  if (canvas.width !== width || canvas.height !== height) {
    renderer.setSize(width, height, false);
  }
}

window.onresize = (e) => {
  onWindowResize();
};

function animate() {
  render();
  requestAnimationFrame(animate);
}

function render() {
  canvas.style.transform = `translateY(${window.scrollY}px)`;

  renderer.setScissorTest(false);
  renderer.clear();

  renderer.setScissorTest(true);

  scenes.forEach(function (scene) {
    // draw the scene
    const element = scene.userData.element;
    // get its position relative to the page's viewport
    const rect = element.getBoundingClientRect();
    // set the viewport
    const width = rect.right - rect.left;
    const height = rect.bottom - rect.top;
    const left = rect.left;
    const bottom = renderer.domElement.clientHeight - rect.bottom;

    renderer.setViewport(left, bottom, width, height);
    renderer.setScissor(left, bottom, width, height);

    const camera = scene.userData.camera;

    camera.aspect = width / height; // not changing in this example
    camera.updateProjectionMatrix();

    scene.userData.controls.update();

    renderer.render(scene, camera);
  });
}
