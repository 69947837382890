import {
  DirectionalLight,
  HemisphereLight,
  AmbientLight,
  PointLight,
} from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

const loader = new FBXLoader();
export const modelLoader = (path, progress) => {
  return new Promise((resolve, reject) => {
    loader.load(
      path,
      (fbxData) => {
        resolve(fbxData);
        setTimeout(() => (progress.style.width = 0), 1000);
      },
      (xhr) => (progress.style.width = `${(xhr.loaded / xhr.total) * 100}%`),
      reject
    );
  });
};

export const createLights = (sizeUnit) => {
  const hemiLight = new HemisphereLight(0xffffff, 0xf9f9f9, 1);

  const dirLight = new DirectionalLight(0xffffff, sizeUnit);
  dirLight.position.set(sizeUnit * 12, sizeUnit * 20, sizeUnit * 12);
  dirLight.castShadow = true;

  const d = sizeUnit;
  dirLight.shadow.camera.left = -d;
  dirLight.shadow.camera.right = d;
  dirLight.shadow.camera.top = d;
  dirLight.shadow.camera.bottom = -d;

  const ambientLight = new AmbientLight(0xffffff);
  ambientLight.intensity = 1;
  ambientLight.position.set(sizeUnit * 3, sizeUnit * 3, sizeUnit * 3);

  const pointLight = new PointLight(0x080820);
  pointLight.position.set(-sizeUnit, sizeUnit, -sizeUnit);

  return [hemiLight, dirLight, ambientLight, pointLight];
};
